import type { ALARM_LEVEL } from '~/types/alarm';
import type { WbrOrientation } from '~/types/configurationDevice';
import type { EquipmentStatusMap } from '~/types/equipment';
import type {
  BatterySensorType,
  GasSensorType,
  RadiationSensorType,
  GpsSensorType,
  LteSignalStrengthSensorType,
  PhysiologicalTemperatureSensorType,
  OxygenSupplySensorType,
  HeartRateSensorType,
} from '~/types/sensor';
import type { STREAM_REQUESTED_STATUS, IvsStream, VideoStreamV2 } from '~/types/videoStream';

export enum AGENT_STATUS {
  alert = 'alert',
  connectionLost = 'connectionLost',
  inMission = 'inMission',
  inSafeZone = 'inSafeZone',
}

export interface AgentAttributes {
  acronym: string;
  first_name?: string;
  last_name?: string;
  phone_number?: string;
  team?: string;
  plate_number?: string;
  in_safe_zone?: boolean;
}

export interface AgentMergedDeviceConfiguration {
  __typename: 'DeviceConfiguration';
  wbr_orientation: WbrOrientation;
  embedded_sound: boolean;
}

export interface AgentMergedAlarmConfiguration {
  __typename: 'AlarmConfiguration';
  activated: boolean;
  activate_video: boolean;
}

export type AgentAlertLevel = ALARM_LEVEL | null;

export interface Agent {
  __typename: 'Carrier_Cognito' | 'Carrier_NonCognito';
  id: string;
  email: string | null;
  completeName: string;
  team: string;
  deviceName: string | null;
  attributes: AgentAttributes;
  isConnected: boolean;
  isConnectionLost: boolean;
  isOffline: boolean;
  sensors: {
    gps: GpsSensorType | null;
    heartRate: HeartRateSensorType | null;
    physiologicalTemperature: PhysiologicalTemperatureSensorType | null;
    gas: GasSensorType | null;
    radiation: RadiationSensorType | null;
    oxygenSupply: OxygenSupplySensorType | null;
    battery: BatterySensorType | null;
    lteSignalStrength: LteSignalStrengthSensorType | null;
  };
  alertLevel: AgentAlertLevel;
  status: AGENT_STATUS;
  equipmentStatus: EquipmentStatusMap;
  missionStartTimeISO: string;
  missionEndTimeISO: string;
  lastUpdate?: string;
  requestedVideoStreamStatus?: STREAM_REQUESTED_STATUS;
  video_stream_v2?: VideoStreamV2 | null;
  /** @deprecated Delete this when we fully move to KVS */
  video_stream?: IvsStream;
  createdAt: string;
}
