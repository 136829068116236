import Hls from 'hls.js';
import { memo, type CSSProperties } from 'react';
import ReactPlayer from 'react-player';

if (typeof window !== 'undefined') {
  window.Hls = Hls; // This makes react-player use the local hls.js instead of fetching from cdn.jsdelivr.net
}

interface Props {
  url: string | undefined;
  playing?: boolean;
  controls?: boolean;
  loop?: boolean;
  volume?: number;
  muted?: boolean;
  width?: number | string;
  height?: number | string;
  playbackRate?: number;
  playsinline?: boolean;
  file?: {
    forceHLS?: boolean;
    forceSafariHLS?: boolean;
  };
  className?: string;
  style?: CSSProperties;
  'data-id'?: string;
}

const VideoPlayer = memo(
  ({
    url,
    playing = false,
    controls = true,
    loop = false,
    volume = 1,
    muted = false,
    width = '100%',
    height = '360px',
    playbackRate = 1,
    playsinline = true,
    file = {
      forceHLS: true,
      forceSafariHLS: true,
    },
    className,
    style,
    'data-id': dataId,
  }: Props) => (
    <ReactPlayer
      url={url}
      playing={playing}
      controls={controls}
      loop={loop}
      volume={volume}
      muted={muted}
      width={width}
      height={height}
      playbackRate={playbackRate}
      file={file}
      playsinline={playsinline}
      pip={false}
      className={className}
      style={style}
      data-id={dataId}
    />
  ),
);

VideoPlayer.displayName = 'VideoPlayer';

export default VideoPlayer;
