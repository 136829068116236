type LoggerArgs = [title: `${string}: ${string}`, data?: Record<string, unknown>];

const logger = {
  log: (...args: LoggerArgs) => {
    console.log(...args); // eslint-disable-line no-console
  },
  error: (...args: LoggerArgs) => {
    console.error(...args); // eslint-disable-line no-console
  },
};

export default logger;

// KVS Logger

interface KvsLoggerParams {
  agentId: string | undefined;
  metricName: string;
  data?: Record<string, unknown>;
}

function buildKvsLogMessage(params: KvsLoggerParams) {
  return `KVS_${params.agentId}_${params.metricName}_${new Date().toISOString()}`;
}

export const kvsLogger = {
  log: (params: KvsLoggerParams) => {
    // eslint-disable-next-line no-console
    console.log(buildKvsLogMessage(params), params.data);
  },
  error: (params: KvsLoggerParams) => {
    // eslint-disable-next-line no-console
    console.error(buildKvsLogMessage(params), params.data);
  },
};
