import { defaultDataIdFromObject, InMemoryCache, type TypePolicy } from '@apollo/client';

const typePolicy: TypePolicy = {
  fields: {
    device: {
      merge(existing, incoming, { mergeObjects }) {
        return mergeObjects(existing, incoming);
      },
    },
    attributes: {
      merge(existing, incoming) {
        return incoming;
      },
    },
    video_stream_v2: {
      merge(existing, incoming, { mergeObjects }) {
        if (existing?.__typename && incoming?.__typename) {
          return mergeObjects(existing, incoming);
        }
        return incoming;
      },
    },
    /** @deprecated Delete this when we fully move to KVS */
    video_stream: {
      merge(existing, incoming, { mergeObjects }) {
        if (existing?.__typename && incoming?.__typename) {
          return mergeObjects(existing, incoming);
        }
        return incoming;
      },
    },
  },
};

export const getNewCacheInstance = () =>
  new InMemoryCache({
    dataIdFromObject: (object) => {
      switch (object.__typename) {
        case 'Device':
          return `Device:${object.name}`;
        default:
          return defaultDataIdFromObject(object);
      }
    },
    typePolicies: {
      Carrier_Cognito: typePolicy,
      Carrier_NonCognito: typePolicy,
    },
  });
