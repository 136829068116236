import Hls from 'hls.js';
import { memo, useState, useEffect, useRef, useCallback, type MouseEvent } from 'react';
import styled from 'styled-components';

import Text from '~/components/Text';
import i18n from '~/locales/i18n';
import theme from '~/theme';
import type { Agent } from '~/types/agent';
import type { VideoStreamV2 } from '~/types/videoStream';
import { kvsLogger } from '~/utils/logger';

const GUTTER = '8px';
const VIDEO_PLAYER_BACKGROUND_COLOR = '#333333';

const VideoWrapperDiv = styled.div`
  border-radius: 6px;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.1);
`;

const PositionRelativeDiv = styled.div`
  position: relative;
  display: block;
`;

const StyledText = styled(Text)`
  height: ${theme.dimensions.videoStreamPlayerHeight}px;
  display: flex;
  font-weight: bold;
  align-items: center;
  justify-content: center;
`;

const VideoPopupDiv = styled.div`
  top: ${GUTTER};
  left: ${GUTTER};
  color: ${theme.colors.darkGrey};
  width: calc(100% - ${GUTTER} - ${GUTTER});
  padding: 12px;
  position: absolute;
  border-radius: 8px;
  pointer-events: none;
  background-color: ${theme.colors.white};
  border: 1px solid rgba(0, 0, 0, 0.1);
`;

interface Props {
  agent: Agent | undefined;
  videoStreamV2: VideoStreamV2 | null;
  isLoadingOrRunning: boolean;
  isStartOrRequestStartLoading: boolean;
  shouldPlayVideoFromAlarm: boolean;
}

const VideoStreamPlayerKvs = memo(
  ({
    agent,
    videoStreamV2,
    isLoadingOrRunning,
    isStartOrRequestStartLoading,
    shouldPlayVideoFromAlarm,
  }: Props) => {
    const [isPopupVisible, setIsPopupVisible] = useState<boolean>(false);

    const handleMouseOut = useCallback((event: MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      setIsPopupVisible(false);
    }, []);

    const handleMouseOver = useCallback((event: MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      setIsPopupVisible(true);
    }, []);

    const videoRef = useRef<HTMLVideoElement>(null);

    const playbackUrl = videoStreamV2?.playback_url;

    useEffect(() => {
      let hls: Hls | null = null;

      if (videoRef.current && playbackUrl) {
        if (Hls.isSupported()) {
          hls = new Hls({
            maxBufferLength: 30,
            maxMaxBufferLength: 60,
            maxBufferSize: 60 * 1000 * 1000,
            maxBufferHole: 0.5,
            lowLatencyMode: true,
            backBufferLength: 30,
            enableWorker: true, // Enable web worker for better performance
            fragLoadingTimeOut: 20000, // Increase timeout for fragment loading
            manifestLoadingTimeOut: 20000, // Increase timeout for manifest loading
            levelLoadingTimeOut: 20000, // Increase timeout for level loading
            startLevel: -1, // Auto start level selection
            abrEwmaDefaultEstimate: 500000, // Increase bandwidth estimate
            abrMaxWithRealBitrate: true, // Use real bitrate for ABR
            progressive: true, // Enable progressive loading
            testBandwidth: true, // Enable bandwidth testing
          });

          hls.loadSource(playbackUrl);
          hls.attachMedia(videoRef.current);

          // Add error handling and recovery
          hls.on(Hls.Events.ERROR, (event, data) => {
            if (data.fatal) {
              const logData = {
                event,
                data,
              };
              kvsLogger.error({
                agentId: agent?.id,
                metricName: `PlayerFatalError-${data.type}`,
                data: logData,
              });
              switch (data.type) {
                case Hls.ErrorTypes.NETWORK_ERROR:
                  kvsLogger.log({
                    agentId: agent?.id,
                    metricName: `PlayerTryToRecoverFromError-${data.type}`,
                    data: logData,
                  });
                  hls?.startLoad(); // Try to recover
                  break;
                case Hls.ErrorTypes.MEDIA_ERROR:
                  kvsLogger.log({
                    agentId: agent?.id,
                    metricName: `PlayerTryToRecoverFromError-${data.type}`,
                    data: logData,
                  });
                  hls?.recoverMediaError(); // Try to recover
                  break;
                default:
                  hls?.destroy();
                  break;
              }
            }
          });

          hls.on(Hls.Events.MANIFEST_PARSED, async () => {
            try {
              await videoRef.current?.play();
              kvsLogger.log({
                agentId: agent?.id,
                metricName: 'PlayerStarted',
                data: {
                  playbackUrl,
                },
              });
            } catch (error) {
              kvsLogger.error({
                agentId: agent?.id,
                metricName: 'PlayerError',
                data: {
                  playbackUrl,
                  error,
                },
              });
            }
          });
        } else {
          kvsLogger.error({
            agentId: agent?.id,
            metricName: 'PlayerUnsupportedBrowser',
            data: {
              playbackUrl,
            },
          });
        }

        // Log when first frame is displayed
        videoRef.current.addEventListener('loadeddata', () => {
          kvsLogger.log({
            agentId: agent?.id,
            metricName: 'PlayerFirstFrameLoaded',
            data: {
              playbackUrl,
            },
          });
        });
      }

      return () => {
        if (hls) {
          hls.destroy();
        }
      };
    }, [playbackUrl, agent?.id]);

    const isVideoSteamRunning = !!videoStreamV2?.playback_url;

    if (!agent) {
      return null;
    }

    const videoPlaceholderText = isStartOrRequestStartLoading
      ? i18n.t('common.loading')
      : i18n.t('carrierDetailsPopup.videoStreaming.offline');

    const mainText = shouldPlayVideoFromAlarm
      ? i18n.t('carrierDetailsPopup.videoStreaming.videoSteamWaitingToStart')
      : videoPlaceholderText;

    const popupText = isStartOrRequestStartLoading
      ? i18n.t('carrierDetailsPopup.videoStreaming.videoSteamWaitingToStart')
      : i18n.t('carrierDetailsPopup.videoStreaming.videoWillStartStreaming');

    return (
      <div>
        {isVideoSteamRunning && playbackUrl ? (
          // eslint-disable-next-line jsx-a11y/media-has-caption
          <video
            ref={videoRef}
            width="100%"
            height={theme.dimensions.videoStreamPlayerHeight}
            playsInline
            controls={false}
            autoPlay
            data-id="video-stream-player"
            style={{
              backgroundColor: VIDEO_PLAYER_BACKGROUND_COLOR,
              borderRadius: '6px',
              overflow: 'hidden',
            }}
          />
        ) : (
          <VideoWrapperDiv>
            <PositionRelativeDiv data-id="video-stream-placeholder">
              {isPopupVisible && <VideoPopupDiv>{popupText}</VideoPopupDiv>}
              <StyledText
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOut}
                style={{
                  backgroundColor: isLoadingOrRunning
                    ? VIDEO_PLAYER_BACKGROUND_COLOR
                    : theme.colors.greyBackground,
                  color: isLoadingOrRunning ? theme.colors.white : theme.colors.darkBlue,
                }}
              >
                {mainText}
              </StyledText>
            </PositionRelativeDiv>
          </VideoWrapperDiv>
        )}
      </div>
    );
  },
);

VideoStreamPlayerKvs.displayName = 'VideoStreamPlayerKvs';

export default VideoStreamPlayerKvs;
