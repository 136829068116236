export enum STREAM_STATE {
  START = 'START',
  END = 'END',
}

export enum STREAM_REQUESTED_STATUS {
  STARTED = 'STARTED',
  STOPPED = 'STOPPED',
}

// KVS Video Stream

export interface VideoStreamV2 {
  __typename?: 'VideoStreamV2';
  playback_url: string | null;
}

// IVS Low Latency and IVS Realtime

export enum STREAM_TYPE {
  IVS_LOW_LATENCY = 'IVS_LOW_LATENCY',
  IVS_REALTIME = 'IVS_REALTIME',
}

export interface IvsStream {
  ivs_stream_type: STREAM_TYPE | null;
  ivs_stream_state: STREAM_STATE | null;
  // IVS Low Latency
  playback_url: string;
  playback_key: string;
  // IVS Realtime
  playback_participant_token: string;
}
