import { memo } from 'react';

import type { CarrierVideoStreamQueryData } from '~/apollo/hooks/videoStream/queries/QueryCarrierVideoStream';
import type { Agent } from '~/types/agent';
import { STREAM_TYPE, type VideoStreamV2 } from '~/types/videoStream';

import VideoStreamPlayerKvs from './components/VideoStreamPlayerKvs';
import VideoStreamPlayerLowLatency from './components/VideoStreamPlayerLowLatency';
import VideoStreamPlayerRealtime from './components/VideoStreamPlayerRealtime';

interface Props {
  agent: Agent;
  videoStreamV2: VideoStreamV2 | null;
  /** @deprecated Delete this when we fully move to KVS */
  videoStream: CarrierVideoStreamQueryData['carrier']['video_stream'] | undefined;
  isLoadingOrRunning: boolean;
  isStartOrRequestStartLoading: boolean;
  shouldPlayVideoFromAlarm: boolean;
}

const VideoStreamPlayerElement = memo(
  ({
    agent,
    videoStreamV2,
    videoStream,
    isLoadingOrRunning,
    isStartOrRequestStartLoading,
    shouldPlayVideoFromAlarm,
  }: Props) => {
    if (videoStreamV2?.playback_url) {
      return (
        <VideoStreamPlayerKvs
          agent={agent}
          videoStreamV2={videoStreamV2}
          isLoadingOrRunning={isLoadingOrRunning}
          isStartOrRequestStartLoading={isStartOrRequestStartLoading}
          shouldPlayVideoFromAlarm={shouldPlayVideoFromAlarm}
        />
      );
    }

    const shouldUseIvsRealtime = videoStream?.ivs_stream_type === STREAM_TYPE.IVS_REALTIME;

    return (
      <div>
        {shouldUseIvsRealtime ? (
          <VideoStreamPlayerRealtime agent={agent} videoStream={videoStream} />
        ) : (
          <VideoStreamPlayerLowLatency
            agent={agent}
            videoStream={videoStream}
            isLoadingOrRunning={isLoadingOrRunning}
            isStartOrRequestStartLoading={isStartOrRequestStartLoading}
            shouldPlayVideoFromAlarm={shouldPlayVideoFromAlarm}
          />
        )}
      </div>
    );
  },
);

VideoStreamPlayerElement.displayName = 'VideoStreamPlayerElement';

export default VideoStreamPlayerElement;
